<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!--
            < !-- hierarchical navigation -- >
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    < !-- <router-link :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account summary</router-link> -- >
                    < !-- <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.realmId } }">Organization</router-link> -- > < !-- TODO: missing organization id ... -- >
                </p>
                </v-col>
            </v-row>
            -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dashboard</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.is_registered_loginshield && !user.is_registered_loginfront">
                        Your profile security settings are incomplete. <router-link :to="{ name: 'profile-setup' }" class="white--text">Set up authentication.</router-link>
                    </v-alert>
                    <template v-if="account && accountBillingSummary">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                            <v-spacer/>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="accountBillingSummary.is_open">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'account-settings', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <!-- <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Name</th>
                                        <td style="width: 100%;">
                                            {{ account.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="min-width: 100px;">Type</th>
                                        <td style="width: 100%;">
                                            {{ accountTypeDisplay }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table> -->
                        <v-card-text>
                            <!-- <p class="text-overline mb-0 mt-2">Account Name</p>
                            <p class="mb-0 pb-0">
                                {{ account.name }}
                            </p>

                            <p class="text-overline mb-0 mt-2">Account Type</p>
                            <p class="mb-0 pb-0">
                                {{ account.type }}
                            </p> -->

                            <template v-if="Array.isArray(accountBillingSummary.service_list) && accountBillingSummary.service_list.length > 0">
                            <p class="text-overline mb-0 mt-2">Services</p>
                            <v-list v-for="(item, idx) in accountBillingSummary.service_list" :key="idx">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name }}
                                            <span v-if="item.is_active"> ({{ serviceTypeDisplay(item.type) }}) - Active</span>
                                            <span v-if="!item.is_active"> - Inactive</span>
                                        </v-list-item-title>
                                        <!-- TODO: possibly all services will have these links, so instead of saas only we should look for anything in PRODUCT_CATEGORY_SERVICE -->
                                        <template v-if="item.type === 'saas'">
                                            <v-list-item-subtitle>
                                                <router-link :to="{ name: 'account-service-edit', params: { brandprofile: $route.params.brandprofile, accountId: $route.params.accountId }, query: { id: item.id } }">Manage</router-link>
                                                <!-- TODO :people who already subscribed don't really need the informatoin websit eurl... that's more for the catalog. what we need here is a link to MANAGE this service (upgrade/downgrade/cancel or see usage stats, etc.) -->
                                                <template v-if="item.product && item.product.content && item.product.content.saas && item.product.content.saas.saas_info_url">
                                                | <a :href="item.product.content.saas.saas_info_url" target="_blank">Website</a>
                                                </template>
                                                <!-- |
                                                <a :href="item.saas_entry_url" target="_blank" v-if="item.saas_entry_url">Login</a> -->
                                            </v-list-item-subtitle>
                                        </template>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="item.type === 'saas'">
                                        <template v-if="item.product && item.product.content && item.product.content.saas && item.product.content.saas.saas_sso_app_tag">
                                            <v-btn :to="{ name: 'brand-sso-redirect-outbound', params: { brandprofile: $route.params.brandprofile }, query: { product_id: item.product_id, account_id: item.account_id } }" :style="primaryButtonStyle">Sign in</v-btn>
                                        </template>
                                        <template v-else-if="item.product && item.product.content && item.product.content.saas && item.product.content.saas.saas_entry_url">
                                            <v-btn :href="item.product.content.saas.saas_entry_url" :style="primaryButtonStyle">Sign in</v-btn>
                                        </template>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            </template>

                            <p class="text-overline mb-0 mt-2">Billing</p>
                            <template v-if="isAccountBillingSummaryReady && (!accountBillingSummary || accountBillingSummary.error)">
                                Billing summary not available at this time.
                            </template>
                            <template v-if="isAccountBillingSummaryReady && accountBillingSummary && !accountBillingSummary.error">
                            <p class="mb-0 pb-0">
                                Account status: {{ accountBillingSummary.is_open ? 'open' : 'closed' }}
                            </p>
                            <p class="mb-0 pb-0" v-if="accountBillingSummary.is_open">
                                Balance due: {{ balanceDisplay }}
                                <span v-if="accountBillingSummary.credit > 0">Credit: {{ creditDisplay }}</span>
                            </p>
                            <p class="mb-0 pb-0" v-if="accountBillingSummary.is_open">
                                <!-- TODO: if payment is due, indicate due date and whether we will automatically charge a card  on that date or if customer has to pay some other way, or if the credit card info is invalid and needs to be updated -->
                                <!-- <a href="#" @click="navigateToBilling"> --> <!-- TODO: navigate to "pay my balance" page -->
                                {{ accountBillingSummary.is_payment_due ? 'Payment is due' : 'No payment is due at this time' }}
                                {{ accountBillingSummary.is_payment_late ? '(late)' : '' }}
                                <!-- </a> -->
                            </p>
                            </template>

                            <!-- TODO: list of products purchased / recent orders / invoices / statements, etc. -->
                            <p class="mb-0 pb-0 mt-4">
                                <router-link :to="{ name: 'account-order-history', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Order history</router-link>
                            </p>
                            <p class="mb-0 pb-0 mt-2">
                                <router-link :to="{ name: 'account-payment-history', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Payment history</router-link>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                            </p>
 -->
                            <!-- <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="profileRoute">My profile</router-link>
                            </p> -->
                            <!-- <p class="text-overline mb-0 mt-10">Organizations</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-organization-list' }">Manage organizations</router-link>
                            </p>
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'service-admin' }">Manage Unicorn Springs</router-link>
                            </p>
                            </template> -->
                        </v-card-text>
                    </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getDisplayText } from '@/sdk/text';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        accountBillingSummary: null,
        isAccountBillingSummaryReady: false, // prevents flicker on related UI items
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            palette: (state) => state.palette,
            brand: (state) => state.brand,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        displayName() {
            if (this.user.info && this.user.info.displayName) {
                return this.user.info.displayName;
            }
            return '(unknown)';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        balanceDisplay() {
            if (Number.isFinite(this.accountBillingSummary?.balance)) {
                const display = new Intl.NumberFormat('en-US', {
                    currency: this.accountBillingSummary.currency,
                    style: 'currency',
                }).format(this.accountBillingSummary.balance);
                return display;
            }
            return '';
        },
        creditDisplay() {
            if (Number.isFinite(this.accountBillingSummary?.credit)) {
                const display = new Intl.NumberFormat('en-US', {
                    currency: this.accountBillingSummary.currency,
                    style: 'currency',
                }).format(this.accountBillingSummary.credit);
                return display;
            }
            return '';
        },
        accountTypeDisplay() {
            return getDisplayText('account_type', this.account?.type);
        },
        accountId() {
            return this.$route.params.accountId;
        },
    },
    watch: {
        focus() {
            // reload data when user returns to this tab
            if (!this.account || this.account.id !== this.$route.params.accountId) {
                this.loadAccount(this.$route.params.accountId);
            }
            this.loadAccountBillingSummary();
        },
    },
    methods: {
        serviceTypeDisplay(type) {
            if (type === 'saas') {
                return 'SaaS';
            }
            if (type === 'membership') {
                return 'Membership';
            }
            return 'Service';
        },
        async loadAccountBillingSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountBillingSummary: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).self.check({ item: 'billing' });
                console.log(`loadAccountBillingSummary: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountBillingSummary = response; // {"is_open":true,"is_payment_due":true,"is_payment_late":false,"balance_amount":"100.00","credit_amount":"20.00","status_on":1234567890}
                } else {
                    this.accountBillingSummary = null;
                }
            } catch (err) {
                console.error('loadAccountBillingSummary: failed to load billing summary report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountBillingSummary: false });
                this.isAccountBillingSummaryReady = true;
            }
        },
        async loadAccount(accountId) {
            await this.$store.dispatch('loadAccount', { accountId });
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
            }
        },
    },
    mounted() {
        if (!this.account || this.account.id !== this.$route.params.accountId) {
            this.loadAccount(this.$route.params.accountId);
        }
        this.loadAccountBillingSummary();
    },
};
</script>
